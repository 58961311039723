import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from 'components/Layout'
import styled from 'styled-components'

const Member = ({ data: { contentfulMember: member } }) => (
  <Layout>
    <Image fluid={member.image.fluid} />
    <Heading>{member.name}</Heading>
    <div>{member.subjects}</div>
    <br />
    <p>{member.description ? member.description.description : null}</p>
  </Layout>
)

export const MemberQuery = graphql`
  query MemberQuery($slug: String!) {
    contentfulMember(slug: { eq: $slug }) {
      id
      name
      subjects
      description {
        description
        childMarkdownRemark {
          excerpt
        }
      }
      image {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
    }
  }
`

export default Member

const Heading = styled.h1`
  color: #ba2c73;
  font-weight: 400;
`

const Image = styled(Img)`
  max-width: 400px;
`
